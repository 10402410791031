/*
 * @Author: SongYijie
 * @Date: 2020-02-10 11:15:35
 * @LastEditors: SongYijie
*/
import { MessageBox } from "mint-ui"
/**
 *
 * @param {*} img
 * 压缩图片
 */
export const compress = (img) => {
  let canvas = document.createElement('canvas')
  let ctx = canvas.getContext('2d')
  var expectWidth = img.naturalWidth
  var expectHeight = img.naturalHeight
  if (img.naturalWidth > img.naturalHeight && img.naturalWidth > 2000) {
    expectWidth = 1200
    expectHeight = expectWidth * img.naturalHeight / img.naturalWidth
  } else if (img.naturalHeight > img.naturalWidth && img.naturalHeight > 2000) {
    expectHeight = 1200
    expectWidth = expectHeight * img.naturalWidth / img.naturalHeight
  }
  canvas.width = expectWidth
  canvas.height = expectHeight
  // 铺底色
  // ctx.fillStyle = "#fff";
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.drawImage(img, 0, 0, expectWidth, expectHeight)
  // 进行最小压缩
  let ndata = canvas.toDataURL('image/jpeg', 0.8)
  return ndata
}

/**
 * 检查图片大小
 */
export const checkImgUpload = file => {
  if (!file) {
    return false
  }
  let size = file.size
  if (size === 0) {
    MessageBox('请选择图片')
    return false
  }

  if (size > 10 * 1024 * 1024) {
    MessageBox('图片过大，请调整尺寸，或者直接用手机拍摄')
    return false
  }
  return true
}

export const userStatus = state => {
  switch (state) {
    case 1:
      return '';
    case 2:
      return '';
    case 3:
      return '实名失败,请重新上传照片';
    case 4:
      return '证照不一致，请重新上传照片';
  } 
}

// 质量压缩, 长宽不变
export const photoCompress = (file,w,cb)=> {
  var ready=new FileReader();
  /*开始读取指定的Blob对象或File对象中的内容. 当读取操作完成时,readyState属性的值会成为DONE,如果设置了onloadend事件处理程序,则调用之.同时,result属性中将包含一个data: URL格式的字符串以表示所读取文件的内容.*/
  ready.readAsDataURL(file);
  ready.onload=function(){
      var re=this.result;
      canvasDataURL(re,w,cb);
  }
}

export const canvasDataURL = (path, obj, cb) => {
  var img = new Image();
  img.src = path;
  img.onload = function(){
      var that = this;
      // 默认按比例压缩
      var w = that.width,
          h = that.height;
      let zoom = 1;
      if (obj.zoom && obj.zoom < 1 && obj.zoom > 0) {
        zoom = obj.zoom;
      }
      w = obj.width || w * zoom;
      h = obj.height || (h * zoom);
      var quality = 0.7;  // 默认图片质量为0.7
      //生成canvas
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      // 创建属性节点
      var anw = document.createAttribute("width");
      anw.nodeValue = w;
      var anh = document.createAttribute("height");
      anh.nodeValue = h;
      canvas.setAttributeNode(anw);
      canvas.setAttributeNode(anh);
      ctx.drawImage(that, 0, 0, w, h);
      
      if (obj.isLandscape) {
        canvas = rotate(-90, canvas);
      }
      // 图像质量
      if(obj.quality && obj.quality <= 1 && obj.quality > 0){
        quality = obj.quality;
      }
      // quality值越小，所绘制出的图像越模糊
      var base64 = canvas.toDataURL('image/jpeg', quality);
      if (obj.isBase64) {
        cb(base64);
        return;
      }
      const arr = base64.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bStr = atob(arr[1]);
      let n = bStr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bStr.charCodeAt(n);
      }
      let blob = new Blob([u8arr], { type: mime });
      // bolo to file
      blob.lastModifiedDate = new Date();
      blob.name = 'face-upload';
      cb(blob);
  }
}

export function rotate(degree, image = this.canvas) {
  degree = ~~degree;
  if (degree !== 0) {
    const maxDegree = 180;
    const minDegree = -90;
    if (degree > maxDegree) {
      degree = maxDegree;
    } else if (degree < minDegree) {
      degree = minDegree;
    }

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const height = image.height;
    const width = image.width;
    const degreePI = (degree * Math.PI) / 180;

    switch (degree) {
      // 逆时针旋转90°
      case -90:
        canvas.width = height;
        canvas.height = width;
        context.rotate(degreePI);
        context.drawImage(image, -width, 0);
        break;
      // 顺时针旋转90°
      case 90:
        canvas.width = height;
        canvas.height = width;
        context.rotate(degreePI);
        context.drawImage(image, 0, -height);
        break;
      // 顺时针旋转180°
      case 180:
        canvas.width = width;
        canvas.height = height;
        context.rotate(degreePI);
        context.drawImage(image, -width, -height);
        break;
      default:
    }
    image = canvas;
  }
  return image;
}